<template>
  <MyProducts />
</template>

<script>
import MyProducts from "@/views/retailer/MyProducts.vue";
export default {
  components: { MyProducts },
};
</script>

<style lang="scss" scoped></style>
